import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ForwardHeader = _resolveComponent("ForwardHeader")!
  const _component_PlaygroundBlock = _resolveComponent("PlaygroundBlock")!
  const _component_b_col = _resolveComponent("b-col")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_TheoryQuestionGroups = _resolveComponent("TheoryQuestionGroups")!
  const _component_MostDifficult = _resolveComponent("MostDifficult")!
  const _component_MoreThenTwoWrong = _resolveComponent("MoreThenTwoWrong")!
  const _component_QuestionSearch = _resolveComponent("QuestionSearch")!
  const _component_DefaultLayout = _resolveComponent("DefaultLayout")!

  return (_openBlock(), _createBlock(_component_DefaultLayout, null, {
    header: _withCtx(() => [
      _createVNode(_component_ForwardHeader)
    ]),
    default: _withCtx(() => [
      _createVNode(_component_b_row, null, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, null, {
            default: _withCtx(() => [
              _createVNode(_component_PlaygroundBlock)
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_b_row, { class: "mt-3 mb-3" }, {
        default: _withCtx(() => [
          _createVNode(_component_b_col, { xl: "9" }, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { xl: "4" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_TheoryQuestionGroups)
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_b_col, {
                    xl: "8",
                    class: "mt-4 mt-xl-0"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MostDifficult)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              _createVNode(_component_b_row, { class: "mt-3" }, {
                default: _withCtx(() => [
                  _createVNode(_component_b_col, { xl: "12" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_MoreThenTwoWrong)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_b_col, {
            xl: "3",
            class: "mt-4 mt-xl-0"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_QuestionSearch)
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}