
import { Options, Vue } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { namespace } from "s-vuex-class";
import { QUESTION_FORMAT_MAP } from "@/maps/QuestionFormatsMap";

const TheoryQuestionGroupModule = namespace("theory-question-groups");
const TheoryQuestionVersionModule = namespace("theory-question-version");

@Options({
  components: { EducationTabs },
})
export default class TheoryQuestionGroup extends Vue {
  @TheoryQuestionGroupModule.Action("findGroups")
  public findGroupsAction: any;

  @TheoryQuestionGroupModule.Getter("getGroups")
  public groups: any;

  @TheoryQuestionGroupModule.Getter("getIsLoading")
  public loading: any;

  get mappedGroups(): Array<any> {
    let groups: Array<any> = [];
    // new questions come every april or october
    groups.push({
      id: "NEW_QUESTIONS",
      name: QUESTION_FORMAT_MAP["NEW_QUESTIONS"],
      count: this.groups["NEW_QUESTIONS"],
    });
    groups.push({
      id: "WITH_VIDEO",
      name: QUESTION_FORMAT_MAP["WITH_VIDEO"],
      count: this.groups["WITH_VIDEO"],
    });
    groups.push({
      id: "TRAFFIC_SIGNS",
      name: QUESTION_FORMAT_MAP["TRAFFIC_SIGNS"],
      count: this.groups["TRAFFIC_SIGNS"],
    });
    groups.push({
      id: "RIGHT_OF_WAY",
      name: QUESTION_FORMAT_MAP["RIGHT_OF_WAY"],
      count: this.groups["RIGHT_OF_WAY"],
    });
    groups.push({
      id: "FREE_TEXT",
      name: QUESTION_FORMAT_MAP["FREE_TEXT"],
      count: this.groups["FREE_TEXT"],
    });
    groups.push({
      id: "WITH_FORMULA",
      name: QUESTION_FORMAT_MAP["WITH_FORMULA"],
      count: this.groups["WITH_FORMULA"],
    });
    groups.push({
      id: "FAVOURITES",
      name: QUESTION_FORMAT_MAP["FAVOURITES"],
      count: this.groups["FAVOURITES"],
    });
    return groups;
  }

  public mounted(): void {
    this.findGroups();
  }

  private selectGroup(group: any): void {
    // if (!group.count) {
    //   return;
    // }
    this.$router.push({
      name: "TheoryQuestionGroup",
      params: {
        id: group.id,
      },
    });
  }

  private findGroups(): void {
    this.findGroupsAction({
      hideSuccessMessage: true,
      params: {},
    });
  }
}
