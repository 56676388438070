
import { Options, Vue, Watch } from "vue-property-decorator";
import { namespace } from "s-vuex-class";
import isEmpty from "lodash/isEmpty";

const MostDifficultModule = namespace("theory-questions-most-difficult");
@Options({
  components: {},
})
export default class MostDifficult extends Vue {
  @MostDifficultModule.Action("findAll")
  public findMostDifficultAction: any;

  @MostDifficultModule.Action("setSelectedEducationClass")
  public setSelectedEducationClassAction: any;

  @MostDifficultModule.Getter("getDataList")
  public mostDifficult: any;

  @MostDifficultModule.Getter("getIsLoading")
  public loading: any;

  @MostDifficultModule.Getter("getSelectedEducationClass")
  public selectedEducationClass: any;

  private topQuestions = [];
  private activeEducationIndex = -1;

  get educationClasses(): Array<string> {
    const classes = Object.keys(this.mostDifficult);
    classes.sort((first: string, second: string) => (first.startsWith("G") ? -1 : 1));
    return classes;
  }

  private setSelectedEducationClass(activeEducationIndex: number): any {
    let index = 0;
    for (const educationClass of this.educationClasses) {
      if (index === activeEducationIndex) {
        this.setSelectedEducationClassAction(educationClass);
        break;
      }
      index++;
    }
  }

  public mounted(): void {
    // TODO use isEmpty also in the other places where the data is in session
    if (isEmpty(this.mostDifficult)) {
      this.loadMostDifficult();
    }
  }

  private loadMostDifficult(): void {
    this.findMostDifficultAction({
      resource: "theory-questions/top-ten-difficult",
      hideSuccessMessage: true,
    });
  }

  private selectQuestion(question: any): void {
    this.$router.push({
      name: "PlaygroundMostDifficult",
      params: {
        question: question.id,
        licenceClass: this.selectedEducationClass,
      },
    });
  }

  @Watch("activeEducationIndex", { immediate: true, deep: true })
  private onActiveEducationIndexChanged(activeEducationIndex: any): void {
    this.setSelectedEducationClass(activeEducationIndex);
    this.topQuestions = this.mostDifficult[this.selectedEducationClass];
  }

  @Watch("$route", { immediate: true, deep: true })
  private onRouteChange(newVal: any): void {
    if (!isEmpty(this.selectedEducationClass)) {
      this.activeEducationIndex = this.educationClasses.findIndex((cl: string) => cl === this.selectedEducationClass);
    }
  }

  public reformatQuestion(question: any): string {
    if (question.length > 60) {
      let formatted = question.slice(0, 60);
      return `${formatted}...`;
    }
    return question;
  }
}
