
import { Options, Vue, Watch } from "vue-property-decorator";
import EducationTabs from "@/components/EducationTabs/EducationTabs.vue";
import { namespace } from "s-vuex-class";
import map from "lodash/map";

const QuestionSearchModule = namespace("theory-questions-search");

@Options({
  components: { EducationTabs },
})
export default class QuestionSearch extends Vue {
  @QuestionSearchModule.Action("filter")
  public questionSearchAction: any;

  @QuestionSearchModule.Action("resetList")
  public resetSearchAction: any;

  @QuestionSearchModule.Getter("getDataList")
  public questions: any;

  @QuestionSearchModule.Getter("getIsLoading")
  public loading: any;

  @QuestionSearchModule.Getter("getTotal")
  public total: any;

  private componentMaxHeight = "230px";
  private officialNumber = "";
  private freeText = "";

  private limit = 20;
  private offset = 0;
  private allQuestions = [];

  /**
   * this method strays  here, only to provide replace long google-ing
   * @private
   */
  private calculateResultsHeight(): void {
    const bodyHeight = document.body.getBoundingClientRect().height;
    const bodyTop = document.body.getBoundingClientRect().top;
    if (this.$refs.questionSearchResultsBox) {
      const boxTop = (this.$refs.questionSearchResultsBox as HTMLDivElement).getBoundingClientRect().top;
      const boxHeight = bodyHeight + bodyTop - boxTop - 80;
      this.componentMaxHeight = `${boxHeight}px`;
    }
  }

  get isSearchAllowed(): boolean {
    return this.officialNumber.length > 0 || this.freeText.length > 2;
  }

  get isLoadMoreAllowed(): boolean {
    return this.offset + this.limit < this.total;
  }

  private selectQuestion(question: any): void {
    this.$router.push({
      name: "PlaygroundTheoryQuestion",
      params: {
        id: [question.id],
      },
    });
  }

  @Watch("questions", { immediate: true, deep: true })
  private onQuestionsChange(questions: any): void {
    if (questions && questions.length) {
      this.allQuestions = this.allQuestions.concat(questions);
    }
  }

  private onSubmit(): void {
    if (!this.officialNumber && !this.freeText) {
      return;
    }
    this.offset = 0;
    this.allQuestions = [];
    this.search();
  }

  private loadMore(): void {
    if (!this.isLoadMoreAllowed) {
      return;
    }
    this.offset += this.limit;
    this.search();
  }

  private search(): void {
    this.questionSearchAction({
      resource: "theory-questions/student",
      extraPath: "filter",
      hideSuccessMessage: true,
      filter: {
        officialNumber: this.officialNumber,
        freeText: this.freeText,
        offset: this.offset,
        limit: this.limit,
      },
    });
  }
  public created(): void {
    window.addEventListener("beforeunload", this.onBeforeUnload);
  }

  public beforeUnmount(): void {
    this.resetSearchAction();
  }

  private onBeforeUnload(e: BeforeUnloadEvent): any {
    // reset if the user hits the F5
    this.resetSearchAction();
    return undefined;
  }

  private selectAllQuestions() {
    const ids: string[] = map(this.allQuestions, "id");
    console.log(ids);
    this.$router.push({
      name: "PlaygroundTheoryQuestion",
      params: {
        id: ids,
      },
    });
  }
}
